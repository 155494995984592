import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import Signin from './app/pages/Signin';
//import * as serviceWorker from './serviceWorker';

// document.addEventListener('DOMContentLoaded', () => {
//   ReactDOM.render(
//     <React.StrictMode>
//      <Router>
//        <Signin />
//      </Router>
//    </React.StrictMode>,
//     document.body.appendChild(document.createElement('div')),
//   )
// })
// ReactDOM.render(
//   <React.StrictMode>
//     <Router>
//       <Signin />
//     </Router>
//   </React.StrictMode>,
//   document.body.appendChild(document.createElement('div'))
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();